import { SoftwarePackage } from '@models/softwarePackge';
import { colors, v2Colors } from '@web-for-marketing/react-core';

export const esSoftwarePackages: SoftwarePackage = {
    seo: {
        title: 'Paquetes de software | Geotab',
        description:
            'Compare las diferentes funciones y elija el plan de tecnología telemática y de gestión de flotas que más le conviene.',
    },
    bannerContent: {
        title: 'Paquetes de software',
        description: 'Compare funciones y elija el plan que más le conviene.',
    },
    softwarePackagesComparisonTable: {
        title: 'Planes en detalle',
        subHeaders: [
            'Productividad',
            'Optimización de la flota',
            'Cumplimiento normativo',
            'Seguridad',
            'Capacidad de expansión',
        ],
        showLessFeatures: 'Mostrar menos funciones',
        showMoreFeatures: 'Mostrar más funciones',
        footNotes: [
            '* Ciertas funciones pueden sufrir retrasos en los planes Base y Pro como consecuencia de limitaciones en los datos, la red o el procesamiento.',
            '** Algunas funciones del plan Base pueden sufrir limitaciones como consecuencia de la incompatibilidad con los datos de estado del motor.',
            '*** Vehículos ligeros, solo EE. UU. y Canadá, con utilización del plan ProPlus a nivel de flota. Se aplican otras condiciones.',
        ],
        buttons: {
            planDetail: {
                text: 'Ver detalles del plan',
                link: 'https://docs.google.com/document/d/1rO7DrAkgpS47Z-YpKAg4drsQd1vUIjHFyNCU4qiVA80/edit?usp=sharing',
            },
            contact: {
                text: 'Contacto de ventas',
                link: '/es/contacto/consulta-de-ventas/',
            },
        },
        plansAvailability: {
            base: true,
            regulatory: true,
            pro: true,
            proPlus: true,
        },
        baseDetails: [
            {
                index: 0,
                cols: 1,
                title: 'Base',
                color: v2Colors.core.innovation,
                subTitle: 'Disponible en todo el mundo',
                description:
                    'El plan Base ofrece ubicación GPS, VIN (odómetro), identificación del conductor y compatibilidad básica con IOX.',
            },
            {
                index: 1,
                cols: 1,
                title: 'EU Regulatory',
                color: colors.core.webEssentials.red,
                subTitle: 'Disponible en Europa',
                description:
                    'El plan “EU Regulatory” ofrece las funcionalidades del plan Base y añade información específica para los vehículos pesados.',
            },
            {
                index: 2,
                cols: 1,
                title: 'Pro',
                color: v2Colors.core.geotab,
                subTitle: 'Disponible en todo el mundo',
                description:
                    'El plan Pro ofrece las funciones del plan Base, además de la compatibilidad con datos del motor y el acelerómetro incluidos datos de vehículos eléctricos.',
            },
            {
                index: 3,
                cols: 1,
                title: 'ProPlus',
                color: colors.core.webEssentials.green,
                subTitle: 'Disponible en todo el mundo',
                description:
                    'El plan ProPlus ofrece las máximas funciones, incluido Seguimiento activo (Active Tracking), una garantía de por vida y servicios premium.',
            },
        ],
        baseDataDetails: [
            {
                index: 0,
                title: '',
                iconColor: v2Colors.core.innovation,
                data: ['Ubicación GPS', 'VIN', 'Identificación del conductor', 'Compatibilidad básica con IOX'],
            },
            {
                index: 1,
                title: '',
                iconColor: colors.core.webEssentials.red,
                data: ['Identificación del conductor', 'Descarga remota de datos', 'Estado en tiempo real'],
            },
            {
                index: 2,
                title: 'Todas las funciones de Base más',
                iconColor: v2Colors.core.geotab,
                data: [
                    'Control de la temperatura',
                    'Datos del motor',
                    'Datos del acelerómetro',
                    'Compatibilidad con vehículos eléctricos',
                ],
            },
            {
                index: 3,
                title: 'Todas las funciones de Pro más',
                iconColor: colors.core.webEssentials.green,
                data: [
                    'Seguimiento activo (Active Tracking)',
                    'Garantía de por vida',
                    'Compatibilidad avanzada con IOX',
                    'Módulo AFA: Gestión de infracciones a través de IOX-uReader',
                ],
            },
        ],
        productivityPlans: [
            {
                title: 'Seguimiento en tiempo real a través de GPS utilizando algoritmos patentados basados en curvas para un registro óptimo',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Reglas flexibles y excepciones para el motor: personalizables y editables',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '**',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Notificaciones de agrupamiento de conductores',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Ubicación del vehículo más próximo por dirección',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Identificación del conductor a través del IOX-uReader',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Mensajes y envío de rutas a conductores con dispositivos de navegación personal compatibles con Garmin',
                boldTitlePrefix: '',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        fleetOptimizationPlans: [
            {
                title: 'Recordatorios de mantenimiento de vehículos programados por tiempo o distancia',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Recordatorios de mantenimiento de vehículos programados por kilometraje o tiempo que el motor ha estado encendido',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Plataforma de gestión de combustible: seguimiento de repostajes de combustible, vaciado de combustible, robo de combustible, integración en tarjeta de combustible, etc.',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Datos de estado del motor para todos los protocolos de motores principales (uso de combustible, tensión, refrigerante, temperatura)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Instalación sencilla de dispositivos con acelerómetro de calibración automática',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Registro e interpretación de datos de errores (J1939, 1708, OBD, CAN)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Datos de motor a través del IOX-uReader',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Add-In Geotab Roadside para servicios gratuitos de remolque, apertura de cierres, aumento de carga de la batería, suministro de combustible, cambio de neumáticos pinchados y más***.',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        compliancePlans: [
            {
                title: 'Detección de manipulación de dispositivos',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Verificaciones de vehículos a través de Geotab Drive (iOS / Android)',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Aplicación de controlador (Geotab Drive) escalable para futuras necesidades',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Cumplimiento de HOS a través de Geotab Drive (Android / iOS) y Garmin',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Modo privado',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Descarga remota (RDL) a través de IOX-uReader',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Estado en tiempo real (TAC) a través de IOX-uReader',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Gestión de infracciones (AFA) a través de IOX-uReader',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Temperatura y otros datos telemáticos ',
                base: false,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Comunicación bidireccional (Configuración de umbrales de temperatura y desactivación de alertas de error)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        safetyPlans: [
            {
                title: 'Alertas acústicas y notificaciones en caso de exceso de velocidad, ralentí e identificación de conductores',
                boldTitlePrefix: 'Asesoramiento básico al conductor -- ',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Alertas acústicas y notificaciones en caso de frenado brusco, curvas cerradas, aceleración excesiva, uso del cinturón de seguridad, vehículo en marcha atrás, exceso de revoluciones, etc., además del asesoramiento básico al conductor y reglas y excepciones personalizables en el extremo del servidor.',
                boldTitlePrefix: 'Asesoramiento avanzado al conductor --',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '*',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
        expandabilityPlans: [
            {
                title: 'Plataforma de software de notificación basada en web (SaaS) capaz de admitir un número ilimitado de vehículos y usuarios',
                boldTitlePrefix: '',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: '',
                title: 'Compatibilidad con diversos tipos de mapas',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Función de notificación con abundantes funciones: editable, personalizable, tendencias, etc.',
                base: true,
                baseAsterisk: '**',
                regulatory: true,
                regulatoryAsterisk: '**',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Paneles de gráficos personalizables',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Control de acceso de usuarios flexible',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Acceso a MyGeotab mediante Android e iOS',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Kit de desarrollo de software e interfaces de programación de aplicaciones libres, gratuitos y fáciles de usar',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Acceso a add-ons y soluciones de socios mediante el Geotab Marketplace',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: '',
                title: 'Integración básica de add-ons de IOX (IOX-GOTALK, IOX-AUXM, IOX-BUZZ, IOX-NFCREADERA, etc.) con periféricos y dispositivos del vehículo',
                base: true,
                baseAsterisk: '',
                regulatory: true,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                boldTitlePrefix: '',
                title: 'Integración avanzada de add-ons de IOX con periféricos y dispositivos del vehículo (IOX-BT, IOX-USB, IOX-CAN y add-ons para controladores de esparcidores de sustancia IOX-RS232M/F/D)	',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Seguimiento activo: solución de seguimiento de la máxima calidad que ofrece información sobre ubicaciones con mayor frecuencia y precisión',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Energía consumida durante la conducción de vehículos eléctricos',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Porcentaje de carga de la batería de vehículos eléctricos (estado de carga)',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Estado de carga de vehículos eléctricos',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Energía de carga de vehículos eléctricos',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: true,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
            {
                title: 'Garantía limitada de por vida para dispositivos',
                base: false,
                baseAsterisk: '',
                regulatory: false,
                regulatoryAsterisk: '',
                pro: false,
                proAsterisk: '',
                proPlus: true,
                proPlusAsterisk: '',
            },
        ],
    },
};
